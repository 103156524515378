import React from "react";
import "./Achievements.css";

const achievementsData = [
  { id: 1, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827218/a2_miwjip.jpg", title: "Achievement 1", description: "This is a description of achievement 1." },
  { id: 2, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827302/a3_buhxk2.jpg", title: "Achievement 2", description: "This is a description of achievement 2." },
  { id: 3, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827144/a1_f95pxv.jpg", title: "Achievement 3", description: "This is a description of achievement 3." },
  // { id: 4, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827218/a2_miwjip.jpg", title: "Achievement 4", description: "This is a description of achievement 4." },
  // { id: 5, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827144/a1_f95pxv.jpg", title: "Achievement 5", description: "This is a description of achievement 5." },
  // { id: 6, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726827302/a3_buhxk2.jpg", title: "Achievement 6", description: "This is a description of achievement 6." },
];

const Achievements = () => {
  return (
    <div className="achievements-container">
      <h2 className="achievements-heading">Achievements</h2>
      <div className="image-section" style={{ width: '100%', display: 'inline-block' }}>
    <img
      src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727082400/Dealer_Certificate_-_Dev_Innovative_Solution_page-0001_nje6cu.jpg"
      alt="Our Brands"
      className="brand-info-image"
      style={{ width: '70%' }} // Adjusts the image to fit within the section
    />
  </div>
      <div className="achievements-cards">
        {achievementsData.map((achievement) => (
          <div key={achievement.id} className="card">
            <div className="card-image-container">
              <img src={achievement.imgSrc} alt={achievement.title} className="card-image" />
            </div>
            <div className="card-content">
              <h3>{achievement.title}</h3>
              <p>{achievement.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
