import React from 'react';
import './Membership.css';

const Membership = () => {
  return (
    <div className="membership-container">
      {/* About Our Bhavanam Section */}
      <p className="about-bhavanam">
        Brahmin Sangam Bhavanam offers a serene and spacious environment for all community events, ensuring every moment is special.
      </p>

      {/* Membership Heading */}
      <h2 className="membership-heading">Membership Information</h2>

      {/* Membership Table */}
      <div className="table-container">
        <table className="membership-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Membership Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Gowtham</td>
              <td>9876543210</td>
              <td>Gold</td>
            </tr>
            <tr>
              <td>Karthik</td>
              <td>9123456780</td>
              <td>Silver</td>
            </tr>
            <tr>
              <td>Harsha</td>
              <td>9823456700</td>
              <td>Platinum</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Membership;
