import React, { useState } from 'react';
import './Gallery.css';

const images = [
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823615/m6_xjnatl.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823664/m8_cm5itd.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823703/m9_hxyflb.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726822971/m1_btpsj8.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823089/m3_vlu6yv.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823177/m5_dapiib.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823136/m4_r67abl.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823703/m9_hxyflb.jpg',
  'https://res.cloudinary.com/dstg58cmo/image/upload/v1726823052/m2_dftmgo.jpg',
];

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    
    <div className="gallery-container">
      <h2 className="gallery-heading">Our Collection</h2> {/* Heading added here */}
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div
            key={index}
            className="gallery-card"
            onClick={() => openModal(index)}
          >
            <img src={image} alt={`Gallery item ${index}`} />
          </div>
        ))}
      </div>

      {modalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>&times;</button>
            <img src={images[currentIndex]} alt={`Modal item ${currentIndex}`} />
            <button className="modal-prev" onClick={prevImage}>&lt;</button>
            <button className="modal-next" onClick={nextImage}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default Gallery;