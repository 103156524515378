import React from "react";
import "./Principals.css";

const principalsData = [
  { id: 1, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830488/m12_iungyr.png", altText: "Principal 1" },
  { id: 2, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830254/m10_erfzlo.png", altText: "Principal 2" },
  { id: 3, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830351/m11_myg7vt.png", altText: "Principal 3" },
  { id: 4, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830654/m13_rsmcpa.png", altText: "Principal 4" },
  // { id: 5, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830488/m12_iungyr.png", altText: "Principal 5" },
  // { id: 6, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830351/m11_myg7vt.png", altText: "Principal 6" },
  // { id: 7, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830654/m13_rsmcpa.png", altText: "Principal 7" },
  // { id: 8, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830488/m12_iungyr.png", altText: "Principal 8" },
  // { id: 9, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830254/m10_erfzlo.png", altText: "Principal 9" },
  // { id: 10, imgSrc: "https://res.cloudinary.com/dstg58cmo/image/upload/v1726830351/m11_myg7vt.png", altText: "Principal 10" },
];

const Principals = () => {
  return (
    <div className="principals-container">
      <h2 className="principals-heading">Our Principals</h2>
      <div className="principals-grid">
        {principalsData.map((principal) => (
          <div key={principal.id} className="card">
            <img src={principal.imgSrc} alt={principal.altText} className="card-image" />
          </div>
        ))}
      </div>
      <div className="newsletter">
        <h3>Subscribe to Our Newsletter</h3>
        <form>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" required />
          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
  );
};

export default Principals;
