
// import React from 'react';
// import './Home.css';

// const Home = () => {
//   return (
//     <div className="home-container">
//       {/* Dev Technologies Heading */}
//       <h1 className="main-heading">Dev Technologies</h1>

//       {/* Carousel Section */}
//       <div className="carousel-section">
//         <div className="carousel">
//           <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1726822971/m1_btpsj8.jpg" alt="Carousel 1" />
//         </div>
//         <div className="carousel">
//           <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1726823052/m2_dftmgo.jpg" alt="Carousel 2" />
//         </div>
//       </div>

//       {/* Our Motors Heading */}
//       <h2 className="motors-heading">Our Motors</h2>

//       {/* Cards Section */}
//       <div className="cards-section">
//         <div className="image-card">
//           <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1726823089/m3_vlu6yv.jpg" alt="Motor 1" />
//         </div>
//         <div className="image-card">
//           <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1726823136/m4_r67abl.jpg" alt="Motor 2" />
//         </div>
//         <div className="image-card">
//           <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1726823177/m5_dapiib.jpg" alt="Motor 3" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;



import React, { useState, useEffect } from 'react';
import './Home.css';

const carouselImages = [
  "https://res.cloudinary.com/dstg58cmo/image/upload/v1726911778/ksb4_l8buqo.jpg",
  "https://res.cloudinary.com/dstg58cmo/image/upload/v1726911655/ksb3_wophve.jpg",
  "https://res.cloudinary.com/dstg58cmo/image/upload/v1726911883/ksb6_gdocm6.jpg" // Add more images if needed
];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Autoplay functionality with fade animation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">
      {/* Dev Technologies Heading */}


      {/* Full-width Container with Background Image and Text Overlay */}
      <div className="promo-banner">
        <div className="overlay-content">
          <h2>Dev Innovative Solutions</h2>
        </div>
      </div>
      <br></br>
      {/* <h1 className="main-heading">Dev Technologies</h1> */}

      {/* Carousel Section */}
      <div className="carousel-section">
        <div className="carousel fade">
          <img
            src={carouselImages[currentIndex]}
            alt={`Carousel ${currentIndex + 1}`}
            className="carousel-image"
          />
        </div>
      </div>

      {/* Our Motors Heading */}
      <h2 className="motors-heading">Our Products</h2>

      {/* Cards Section */}
      <div className="cards-section">
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727075614/gen1_k8pduu.png" alt="Motor 1" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727080772/dom33_cbz0dl.jpg" alt="Motor 2" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727080491/dom1_pwx3hr.jpg" alt="Motor 3" />
        </div>
      </div><br></br>

      <div className="cards-section">
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727073899/schendier_oniybn.png" alt="Motor 1" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727072656/3phase2_yffoub.jpg" alt="Motor 2" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727072003/polycab-ht-lt-cables_zjefdf.jpg" alt="Motor 3" />
        </div>
      </div>

      <h2 className="motors-heading">Our Brands</h2>
      {/* Cards Section */}
      <div className="cards-section">
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727159988/ksblogo_ktn42e.png" alt="Motor 1" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727160005/Polycab-India-Ltd-Logo_bstug3.png" alt="Motor 2" />
        </div>
        <div className="image-card">
          <img src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727160089/sch_nt5iel.png" alt="Motor 3" />
        </div>
      </div>


      <div className="brand-info-section">
        <div className="text-section" style={{ width: '70%', display: 'inline-block' }}>
          <h2 className="brand-info-heading">Achievements</h2>
          <p className="brand-info-paragraph">
            What the mind can conceive and believe, and the heart desire, you can achieve.WE ARE PASSIONATE:
          </p>
          <ul className="brand-info-list">
            <li className="brand-info-item">
              <i className="fas fa-check-circle" aria-hidden="true"></i> EXCELLENT PERFORMANCE PROJECT BUSINESS (SWITCHGEAR)
            </li>
            <li className="brand-info-item">
              <i className="fas fa-check-circle" aria-hidden="true"></i> OVERALL BEST PERFORMANCE (SWITCHGEAR)
            </li>
            <li className="brand-info-item">
              <i className="fas fa-check-circle" aria-hidden="true"></i>HIGHEST QUANTITY-CONTACTORS
            </li>
          </ul>
        </div>

      </div>

      <div>

        <div className="image-section" style={{ width: '100%', display: 'inline-block' }}>
          <img
            src="https://res.cloudinary.com/dstg58cmo/image/upload/v1727082400/Dealer_Certificate_-_Dev_Innovative_Solution_page-0001_nje6cu.jpg"
            alt="Our Brands"
            className="brand-info-image"
            style={{ width: '70%' }} // Adjusts the image to fit within the section
          />
        </div>
      </div>



    </div>
  );
};

export default Home;

