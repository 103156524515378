import React, { useState } from "react";
import "./Product.css";

const Product = () => {
  // State for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Product data
  const productData = [
    { heading: "KSB Motors", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727070395/ksb11_oor6fx.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727070608/ksb33_ztu12p.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1726911655/ksb3_wophve.jpg"] },
    { heading: "Industrial Pumps", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727071049/indus1_uiy0vv.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727071095/indus2_cm0rjp.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727071131/indus3_ivapkp.jpg"] },
    { heading: "Water Level Control", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727071392/water1_b5qdgw.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727071433/water2_jvjac7.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727071462/water3_lln000.jpg"] },
    { heading: "Polycab Power Cabels", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727072003/polycab-ht-lt-cables_zjefdf.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072098/poly2_gdv5pr.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072063/poly1_sh78rp.jpg"] },
    { heading: "CG Single Phase Motors", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727072351/cg1_j59zif.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072390/cg2_ip1wuw.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072817/single_exfeym.jpg"] },
    { heading: "CG 3 Phase General & FLP Motors", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727072621/3phase1_fy3wnm.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072656/3phase2_yffoub.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727072702/3phase3_r4mjsn.png"] },
    { heading: "Siemens Switch Gare", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727073097/siemen1_ocs8uz.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727073147/siemens-switchgear_obocpv.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727073183/siemen2_vfs4mm.jpg"] },
    { heading: "Schneider Switch Gare", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727073415/schneider-switchgears_mzyn3d.png", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727073865/schendier22_lpo71b.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727073899/schendier_oniybn.png"] },
    { heading: "Generators", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727075614/gen1_k8pduu.png", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727075655/Generator-PNG-HD-Quality_hqzhhr.png", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727075739/500-kva-greaves-power-diesel-generator-3-phase_ibarii.png"] },
    { heading: "Control Panels", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727075944/control1_oexhsi.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727075980/control2_cahopi.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727076014/control3_jq8h16.jpg"] },
    { heading: "Domestic Pumps", images: ["https://res.cloudinary.com/dstg58cmo/image/upload/v1727080491/dom1_pwx3hr.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727080540/dom3_obqeke.jpg", "https://res.cloudinary.com/dstg58cmo/image/upload/v1727080772/dom33_cbz0dl.jpg"] },
  ];

  // Function to handle image click
  const handleImageClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="product-page">
      <div className="product-header">
        <h1>Products</h1>
      </div>

      {productData.map((section, index) => (
        <div key={index} className="product-section">
          <h2>{section.heading}</h2>
          <div className="product-cards">
            {section.images.map((image, imgIndex) => (
              <div
                key={imgIndex}
                className="product-card"
                onClick={() => handleImageClick(section.heading)}
              >
                <img src={image} alt={section.heading} />
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{selectedProduct}</h2>
            <h3>Dev Technologies</h3>
            <p>
              For purchase, call this number: 
              <a href="tel:+918008700553" className="phone-link">
                <span role="img" aria-label="mobile">📞</span>8008700553
              </a>
            </p>
            <button onClick={closeModal} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
