import React from 'react';
import './About.css';

const About = () => {
  const reviews = [
    {
      name: 'Gowtham',
      text: '"Dev Technologies provided excellent machines and motors. Outstanding quality, reliable performance, and great customer service!"',
      stars: 5,
    },
    {
      name: 'Karthik',
      text: '"Dev Technologies provided excellent machines and motors. Outstanding quality, reliable performance, and great customer service!"',
      stars: 5,
    },
    {
      name: 'Harsha',
      text: '"Dev Technologies provided excellent machines and motors. Outstanding quality, reliable performance, and great customer service!"',
      stars: 5,
    },
  ];

  const renderStars = (count) => {
    return [...Array(count)].map((_, i) => (
      <img
        key={i}
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Plain_Yellow_Star.png/768px-Plain_Yellow_Star.png"
        alt="star"
        className="star"
      />
    ));
  };

  return (
    <div className="about-container">
       

{/* Full-width Container with Background Image and Text Overlay */}
<div className="special-banner">
  <div className="special-text-overlay">
    <h3>About</h3>
    <p>"Dev Technologies: Driving innovation with high-quality machines and motors."</p>
  </div>
</div>
<br></br>

<div className="special-banner11">
  <div className="special-text-overlay11">
    <h2 class="mitra">MITRA TEJA</h2>
    <h5>8008005527</h5>
    <p>Email:sales.devinnovative@gmail.com</p>
  </div>
</div>
      <h1>About Dev Technologies</h1>
      <p className="about-text">
      Dev Technologies is a trusted leader in machines and motor solutions, offering a wide range of high-quality products designed for industrial and commercial use. From cutting-edge motors to precision-engineered machines, we provide reliable and efficient solutions that meet the highest industry standards. Our team is committed to delivering exceptional customer service, ensuring timely delivery and expert support.
        <br /><br />
        Whether you're looking for customized motor solutions or advanced machinery, Dev Technologies is your partner for performance and innovation. Choose us for unparalleled expertise, competitive pricing, and lasting results.

      </p>

      <div className="reviews-section">
        <h2>Customer Reviews</h2>
        <div className="reviews-grid">
          {reviews.map((review, index) => (
            <div key={index} className="review-card">
              <h3>{review.name}</h3>
              <p>{review.text}</p>
              <div className="stars">{renderStars(review.stars)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
