import React from "react";
import "./Offers.css";

const Offers = () => {
  return (
    <div className="offers-container">
      {/* Offers Heading */}
      <h2 className="offers-heading">Exclusive Offers</h2>

      {/* Full-width Container with Background Image and Text Overlay */}
      <div className="offers-banner">
        <div className="text-overlay">
          <h3>Amazing Discounts!</h3>
          <p>Save big on our latest collections with up to 50% off!</p>
        </div>
      </div>
    </div>
  );
};

export default Offers;
