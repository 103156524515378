import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <h2>Contact Us</h2>
          <form className="contact-form">
            <input type="text" placeholder="Name" />
            <input type="tel" placeholder="Phone Number" />
            <input type="email" placeholder="Email" />
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="footer-right">
          <h3>Contact:800870052,8008700553</h3>
          <h5>Email:sales.devinnovative@gmail.com</h5>
          <p>Office Address: Chaganti Residency,near Balabhanu School,Madhavadhara village,Muralinagar,Visakhapatnam-53007</p>
          <p>Showroom Address: Shop No:4,Vishnu Plaza,Dabagardens,Visakhapatnam-530016</p>
        </div>
      </div>
      <p className="footer-bottom">© 2024 Dev Technologies. All rights reserved by Aeries IT.com.</p>
    </footer>
  );
};

export default Footer;
